import React, { createContext, useEffect, useState } from 'react';
import { Flex, Spinner, Center } from '@chakra-ui/react';

// Create a context to track middleware initialization
export const MiddlewareContext = createContext(null);

/**
 * MiddlewareProvider component ensures middleware is initialized
 * before rendering any child components.
 *
 * @param {Object} props - Component props
 * @param {Array<Function>} props.middlewares - Array of middleware functions to initialize
 * @param {React.ReactNode} props.children - Child components to render after initialization
 */
const MiddlewareProvider = ({ middlewares = [], children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initMiddleware = () => {
      try {
        // Execute all middleware functions in sequence (synchronously)
        for (const middleware of middlewares) {
          if (typeof middleware === 'function') {
            middleware();
          }
        }
        // Set initialized immediately after synchronous execution
        setInitialized(true);
      } catch (error) {
        console.error('Error initializing middleware:', error);
        // Still set to true to prevent hanging
        setInitialized(true);
      }
    };

    initMiddleware();
  }, [middlewares]);

  // Show loading spinner while middleware initializes
  if (!initialized) {
    return (
      <Center h="100vh" w="100vw">
        <Flex direction="column" align="center" justify="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Center>
    );
  }

  return (
    <MiddlewareContext.Provider value={{ initialized }}>
      {children}
    </MiddlewareContext.Provider>
  );
};

export default MiddlewareProvider;
