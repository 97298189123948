import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Middlewares and Layouts
import instanceMiddleware from './middleware/instanceMiddleware';
import datadogMiddleware from './middleware/datadogMiddleware';
import MiddlewareProvider from 'shared/molecules/MiddlewareProvider';
import WeblangLayout from './layouts/WeblangLayout';
import EtsyEasySyncLayout from "layouts/EtsyEasySyncLayout";
import SmartFlipbookLayout from "layouts/SmartFlipbookLayout";
import Login from 'shared/pages/Login';

// Weblang
import WeblangHelp from 'apps/Weblang/pages/Help';
import WeblangPricingInfo from 'apps/Weblang/pages/PricingInfo';
import WeblangInstall from 'apps/Weblang/pages/Install';
import WeblangPayment from 'apps/Weblang/pages/Payment';
import EditorInstall from 'apps/Weblang/pages/EditorInstall';
import WeblangLanguages from 'apps/Weblang/pages/Languages';
import WeblangCreateLanguages from 'apps/Weblang/pages/CreateLanguages';
import WeblangEditTranslation from 'apps/Weblang/pages/EditTranslation';
import WeblangCustomiseControls from 'apps/Weblang/pages/CustomiseControls';
import WeblangMultilingualIndex from 'apps/WeblangMultilingual/pages/Index';

// Etsy
import EtsyEasyPricingInfo from 'apps/EtsyEasySync/pages/PricingInfo';
import EtsyEasySyncPayment from 'apps/EtsyEasySync/pages/Payment';
import EtsyEasySyncConnectEtsy from 'apps/EtsyEasySync/pages/ConnectEtsy';
import EtsyEasySyncFinalizeEtsyConnection from 'apps/EtsyEasySync/pages/FinalizeEtsyConnection';
import EtsyEasySyncSettings from 'apps/EtsyEasySync/pages/Settings';
import EtsyEasySyncDefaultPackageSize from 'apps/EtsyEasySync/pages/DefaultPackageSize';
import EtsyEasySyncHelp from 'apps/EtsyEasySync/pages/Help';
import EtsyEasySyncProductMappings from 'apps/EtsyEasySync/pages/ProductMappings';
import EtsyEasySyncSynchronizationHistory from 'apps/EtsyEasySync/pages/SynchronizationHistory';
import EtsyEasySyncSynchronizationRun from 'apps/EtsyEasySync/pages/SynchronizationRun';

// SmartFlipbook
import SmartFlipbookWidgetIframe from 'apps/SmartFlipbook/pages/WidgetIframe'
import SmartFlipbookPricingInfo from "apps/SmartFlipbook/pages/PricingInfo";
import SmartFlipbookPayment from "apps/SmartFlipbook/pages/Payment";
import SmartFlipbookPaymentSuccess from "apps/SmartFlipbook/pages/PaymentSuccess";
import SmartFlipbookHelp from "apps/SmartFlipbook/pages/Help";

const App = () => {
  // Define middlewares to be initialized
  const middlewares = [
    datadogMiddleware,
    instanceMiddleware
  ];

  return (
    <MiddlewareProvider middlewares={middlewares}>
      <Router>
        <Routes>
          {/* Parent route with WeblangLayout that contains child routes */}
          <Route element={<WeblangLayout />}>
            {/* All Weblang pages */}
            <Route path="/weblang/pricing_info" element={<WeblangPricingInfo />} />
            <Route path="/weblang/install" element={<WeblangInstall />} />
            <Route path="/weblang/payment" element={<WeblangPayment />} />
            <Route path="/weblang/editor_install" element={<EditorInstall />} />
            <Route path="/weblang/languages" element={<WeblangLanguages />} />
            <Route path="/weblang/languages/new" element={<WeblangCreateLanguages />} />
            <Route path="/weblang/:languageId/translations" element={<WeblangEditTranslation />} />
            <Route path="/weblang/customise_controls" element={<WeblangCustomiseControls />} />
            <Route path="/weblang/help" element={<WeblangHelp />} />
          </Route>

          <Route element={<EtsyEasySyncLayout />}>
            {/* All Etsy pages */}
            <Route path="/etsy_easy_sync/pricing_info" element={<EtsyEasyPricingInfo />} />
            <Route path="/etsy_easy_sync/payment" element={<EtsyEasySyncPayment />} />
            <Route path="/etsy_easy_sync/connect_etsy" element={<EtsyEasySyncConnectEtsy />} />
            <Route path="/etsy_easy_sync/connect_etsy/finalize" element={<EtsyEasySyncFinalizeEtsyConnection />} />
            <Route path="/etsy_easy_sync/settings" element={<EtsyEasySyncSettings />} />
            <Route path="/etsy_easy_sync/default_package_size" element={<EtsyEasySyncDefaultPackageSize />} />
            <Route path="/etsy_easy_sync/product_mappings" element={<EtsyEasySyncProductMappings />} />
            <Route path="/etsy_easy_sync/synchronization_history" element={<EtsyEasySyncSynchronizationHistory />} />
            <Route path="/etsy_easy_sync/synchronization_history/:synchronizationId" element={<EtsyEasySyncSynchronizationRun />} />
            <Route path="/etsy_easy_sync/help" element={<EtsyEasySyncHelp />} />
          </Route>

          <Route path="/smart_flipbook/widget_iframe" element={<SmartFlipbookWidgetIframe />} />
          <Route element={<SmartFlipbookLayout />}>
            <Route path="/smart_flipbook/help" element={<SmartFlipbookHelp />} />
            <Route path="/smart_flipbook/pricing_info" element={<SmartFlipbookPricingInfo />} />
            <Route path="/smart_flipbook/payment" element={<SmartFlipbookPayment />} />
            <Route path="/smart_flipbook/payment/success" element={<SmartFlipbookPaymentSuccess />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/weblang_multilingual" element={<WeblangMultilingualIndex />} />
          <Route path="/" element={<div>Welcome to Rubyroid Tech UI</div>} />
        </Routes>
      </Router>
    </MiddlewareProvider>
  );
};

export default App;
