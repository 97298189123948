import React from 'react';
import { Box, useColorModeValue as mode } from '@chakra-ui/react';
import { Header } from 'shared/organisms/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { SupportWidget } from "shared/organisms/SupportWidget";

const SmartFlipbookLayout = () => {
  const location = useLocation();

  // Links for the header navigation
  const links = [
    { label: 'Billing', href: '/smart_flipbook/payment' },
    { label: 'Help', href: '/smart_flipbook/help' },
  ];

  return (
    <Box bg={mode('gray.100', 'gray.700')}>
      <Header
        homeHref="/smart_flipbook/payment"
        logo="/smart_flipbook/logo.png"
        links={links}
        activeLink={location.pathname}
      />
      <Box maxW="7xl" as="main" py="6" px="4" mx="auto" minHeight="calc(100vh - 120px)">
        <Outlet />
      </Box>

      <SupportWidget />
    </Box>
  );
};

export default SmartFlipbookLayout;
