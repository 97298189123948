import { jwtDecode } from 'jwt-decode';
import { datadogRum } from '@datadog/browser-rum';

// Checks if the user should be redirected to login page
const shouldRedirectToLogin = (instanceId) => {
  // List of paths that don't require an instance ID
  const exemptPaths = [
    '/login',
    '/weblang_multilingual',
    '/etsy_easy_sync/connect_etsy/finalize',
    '/etsy_easy_sync/payment',
    '/smart_flipbook/widget_iframe'
  ];

  const currentPath = window.location.pathname;

  // Check if current path is exempt
  const isExemptPath = exemptPaths.some(path =>
    currentPath === path || currentPath.startsWith(path)
  );

  // Check if current path is a pricing info page
  const isPricingInfoPage = currentPath.endsWith('pricing_info');

  // Return true if we should redirect (no instanceId and not on exempt path)
  return !instanceId && !isExemptPath && !isPricingInfoPage;
};

const decodeInstance = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // Sanitize URL parameters to remove 'amp;'
  urlParams.forEach((value, key) => {
    if (key.includes('amp;')) {
      const sanitizedKey = key.replace('amp;', '');
      urlParams.delete(key);
      urlParams.set(sanitizedKey, value);
    }
  });

  const instanceIdParam = urlParams.get('instance_id');
  const instanceParam = urlParams.get('instance');

  let instanceId = null;

  if (instanceIdParam) {
    instanceId = instanceIdParam;
  } else if (instanceParam) {
    try {
      const decoded = jwtDecode(instanceParam);
      instanceId = decoded.instanceId;
    } catch (error) {
      console.error('Failed to decode instance JWT', error);
    }
  }

  // Store instanceId in sessionStorage
  if (instanceId) {
    sessionStorage.setItem('instanceId', instanceId);
  } else {
    instanceId = sessionStorage.getItem('instanceId');
  }

  datadogRum.setGlobalContextProperty('instance_id', instanceId);

  // Check if we need to redirect and do so if needed
  if (shouldRedirectToLogin(instanceId)) {
    window.location.replace('/login');
  }

  return instanceId;
};

export default decodeInstance;
