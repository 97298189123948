import React, { useEffect, useState } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import axiosInstance from 'utils/axiosInstance';

export const SupportWidget = () => {
  const [visitorData, setVisitorData] = useState(null);
  const [loadingVisitorData, setLoadingVisitorData] = useState(true);

  useEffect(() => {
    const fetchVisitorData = async () => {
      try {
        const response = await axiosInstance.get('/support_chat/api/configurations');
        setVisitorData(response.data);
      } catch (error) {
        console.error('Failed to fetch visitor data:', error);
      } finally {
        setLoadingVisitorData(false);
      }
    };

    fetchVisitorData();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID && visitorData) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: visitorData.wix_id,
        user_hash: visitorData.user_hash,
        name: visitorData.username,
        email: visitorData.email,
        created_at: Math.floor(new Date(visitorData.created_at).getTime() / 1000),
        ...visitorData.custom_attributes
      });
    }
  }, [visitorData]);

  if (loadingVisitorData) {
    return null; // Do not render the widget if loading
  }

  return <div></div>;
};
