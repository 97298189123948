import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PricingBlock from 'shared/organisms/PricingBlock';
import axiosInstance from 'utils/axiosInstance';

const Payment = () => {
  const navigate = useNavigate();
  const [pricingPlans, setPricingPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch pricing plans
  useEffect(() => {
    const instanceId = sessionStorage.getItem('instanceId');
    if (!instanceId) {
      navigate('/smart_flipbook/pricing_info');
      return;
    }

    const fetchPricingPlans = async () => {
      try {
        const response = await axiosInstance.get('/smart_flipbook/api/payments/new');
        setPricingPlans(response.data.packages || []);
      } catch (err) {
        setError('Failed to load pricing plans.');
      } finally {
        setLoading(false);
      }
    };
    fetchPricingPlans();
  }, [navigate]);

  return (
    <>
      <Box as="section" py="10">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          {/* Header Section */}
          <Flex textAlign="center" align="center" direction="column">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue('blue.600', 'blue.400')}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              Time to Choose a Plan!
            </Heading>
            <Text
              fontSize="l"
              mt="4"
              maxW="2xl"
              mx={{ base: 'auto', lg: 'unset' }}
            >
              Choose one of our two simple plans: pay once to own it or subscribe for ongoing support. No gimmicks, just what you need.
            </Text>
          </Flex>

          {/* Pricing Block */}
          <Box>
            <PricingBlock
              pricingPlans={pricingPlans}
              loading={loading}
              error={error}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Payment;
