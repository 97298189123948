import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  useColorModeValue,
  Text,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import axiosInstance from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

const ShakeBox = ({ children, shouldShake }) => {
  return (
    <motion.div
      animate={shouldShake ? { x: [0, -10, 10, -10, 0] } : {}}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

const DefaultPackageSize = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const boxBg = useColorModeValue('white', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');
  const toast = useToast();

  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [shakeOnValidationFail, setShakeOnValidationFail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await axiosInstance.get('/etsy_easy_sync/api/default_package_sizes/edit');
        setSettings(data);
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Failed to load settings.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [toast]);

  const handleChange = (key, value) => {
    // If a default value is selected, we want to keep it as is.
    if (parseFloat(value) <= 0 && value !== '') return;
    setSettings((prev) => ({
      ...prev,
      [key]: { ...prev[key], value },
    }));
    setShakeOnValidationFail(false);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const payload = {};
      Object.keys(settings).forEach((key) => {
        const currentValue = settings[key].value;
        if (currentValue === '' || currentValue === undefined || currentValue === null) {
          // If no value is set, pick the first option from allowed_values
          if (Array.isArray(settings[key].allowed_values) && settings[key].allowed_values.length > 0) {
            payload[key] = settings[key].allowed_values[0].value;
          } else {
            payload[key] = '';
          }
        } else {
          payload[key] = currentValue;
        }
      });

      await axiosInstance.patch('/etsy_easy_sync/api/default_package_sizes', { default_package_size: payload });
      console.log('Settings saved successfully!');
      navigate('/etsy_easy_sync/product_mappings');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save settings. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" w="full" h="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" w="full" px="6" bg={bgColor}>
      <ShakeBox w="full" shouldShake={shakeOnValidationFail}>
        <Box bg={boxBg} borderRadius="lg" boxShadow="lg" w="xl" p="8">
          <Heading as="h1" size="lg" color={headingColor} mb="2" textAlign="center">
            What's your usual package size?
          </Heading>
          <Text textAlign="center" mb="6" size="sm" color="gray.600">
            We use this information when creating an Etsy product, as it's sometimes required to calculate shipping costs. You can always update these details for individual products directly on Etsy if needed.
          </Text>

          <form onSubmit={handleNext}>
            {settings && (
              <>
                <Flex gap={4}>
                  <FormControl isRequired={settings.item_weight.required}>
                    <FormLabel>{settings.item_weight.displayed_name}</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      min="0.01"
                      value={settings.item_weight.value || ''}
                      onChange={(e) => handleChange('item_weight', e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired={settings.item_weight_unit.required}>
                    <FormLabel>{settings.item_weight_unit.displayed_name}</FormLabel>
                    <Select
                      value={settings.item_weight_unit.value || ''}
                      onChange={(e) => handleChange('item_weight_unit', e.target.value)}
                    >
                      {settings.item_weight_unit.allowed_values.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.displayed_value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>

                <Flex gap={4} mt={4}>
                  <FormControl isRequired={settings.item_length.required}>
                    <FormLabel>{settings.item_length.displayed_name}</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      min="0.01"
                      value={settings.item_length.value || ''}
                      onChange={(e) => handleChange('item_length', e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired={settings.item_width.required}>
                    <FormLabel>{settings.item_width.displayed_name}</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      min="0.01"
                      value={settings.item_width.value || ''}
                      onChange={(e) => handleChange('item_width', e.target.value)}
                    />
                  </FormControl>
                </Flex>

                <Flex gap={4} mt={4}>
                  <FormControl isRequired={settings.item_height.required}>
                    <FormLabel>{settings.item_height.displayed_name}</FormLabel>
                    <Input
                      type="number"
                      step="0.01"
                      min="0.01"
                      value={settings.item_height.value || ''}
                      onChange={(e) => handleChange('item_height', e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired={settings.item_dimensions_unit.required}>
                    <FormLabel>{settings.item_dimensions_unit.displayed_name}</FormLabel>
                    <Select
                      value={settings.item_dimensions_unit.value || ''}
                      onChange={(e) => handleChange('item_dimensions_unit', e.target.value)}
                    >
                      {settings.item_dimensions_unit.allowed_values.map((opt) => (
                        <option key={opt.value} value={opt.value}>
                          {opt.displayed_value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
              </>
            )}

            <Button colorScheme="blue" size="lg" w="full" mt="6" type="submit" isLoading={saving}>
              Next
            </Button>
          </form>
        </Box>
      </ShakeBox>
    </Flex>
  );
};

export default DefaultPackageSize;
