import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PricingBlock from 'shared/organisms/PricingBlock';
import axiosInstance from 'utils/axiosInstance';

const PricingInfo = () => {
  const [pricingPlans, setPricingPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch pricing plans in informational mode
  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const response = await axiosInstance.get('/smart_flipbook/api/payments');
        setPricingPlans(response.data.packages || []);
      } catch (err) {
        setError('Failed to load pricing plans.');
      } finally {
        setLoading(false);
      }
    };
    fetchPricingPlans();
  }, []);

  return (
    <>
      <Box as="section" py="10">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          {/* Header Section */}
          <Flex textAlign="center" align="center" direction="column">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue('blue.600', 'blue.400')}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              Ready to Get Started?
            </Heading>
            <Text
              fontSize="l"
              mt="4"
              maxW="2xl"
              mx={{ base: 'auto', lg: 'unset' }}
            >
              Choose the best plan for you, whether you are just starting out or scaling up. Our
              pricing plans are flexible and transparent, tailored to meet your needs.
            </Text>
          </Flex>

          {/* Pricing Block */}
          <Box mt="10">
            <PricingBlock
              pricingPlans={pricingPlans}
              loading={loading}
              error={error}
              informationalMode={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PricingInfo;
