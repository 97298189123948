import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const decodeBase64 = (encoded) => {
  try {
    return JSON.parse(atob(encoded));
  } catch (error) {
    console.error('Failed to decode Base64 params:', error);
    // Send message to parent about base64 decode error
    window.parent.postMessage({ event: 'flipbookError', message: error.toString() }, '*');
    return {};
  }
};

const attachmentUrl = (hash_id) => {
  if (!hash_id) { return; }
  return `${process.env.REACT_APP_BACKEND_URL}/smart_flipbook/attachment_file_proxy/${hash_id}`
}

const WidgetIframe = () => {
  const [searchParams] = useSearchParams();
  const encodedData = searchParams.get('data');
  const props = encodedData ? decodeBase64(encodedData) : {};
  const type = props.type || 'pdf';
  const displayMode = props.display_mode || 'two_page';
  const defaultZoom = props.default_zoom || 0.8;
  const pdfAttachmentUrl = attachmentUrl(props.pdf_attachment) || '/smart_flipbook/files/bmw-mag.pdf';
  const imageAttachments = props.image_attachments ? JSON.parse(props.image_attachments) : [];
  const imageAttachmentUrls = imageAttachments.map((hashId) => attachmentUrl(hashId));
  const primaryColor = props.primary_color ?? '#111111';

  useEffect(() => {
    // Helper function to dynamically load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    // Helper function to dynamically inject styles
    const injectStyles = (id, styles, target = document.head) => {
      let styleElement = target.querySelector(`#${id}`);
      if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.id = id;
        styleElement.textContent = styles;
        target.append(styleElement);
      }
    };

    injectStyles('document-styles', `
      html, body, #root, .container {
        background: transparent;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    `)

    // Function to initialize the FlipBook
    const initializeFlipBook = () => {
      try {
        const flipBookConfig = {
          ready: (scene) => {
            const [head] = scene.view.head;
            const font = 'Helvetica, Arial, sans-serif'; // Example font
            const styles = `
              .elementInvertedColor svg {
                fill: ${ primaryColor };
              }
              .elementInvertedColor {
                color: ${ primaryColor };
              }
              .elementColor svg {
                fill: ${ primaryColor };
              }
            `;
            injectStyles('widget-styles', styles, head);

            if (font) {
              const fontLink = document.createElement('link');
              fontLink.rel = 'stylesheet';
              fontLink.href = `https://fonts.googleapis.com/css?family=${font}`;
              head.appendChild(fontLink);
            }

            window.parent.postMessage('flipbookReady', '*');
          },
          controlsProps: {
            scale: {
              default: defaultZoom,
              min: 0.8,
              max: 2.5,
            },
            actions: {
              mouseCmdWheelZoom: {
                enabled: false,
              },
              cmdSinglePage: {
                active: displayMode === 'single_page',
                activeForMobile: true,
              },
              cmdSounds: {
                active: false,
              },
            },
          },
          template: {
            html: 'templates/default-book-view.html',
            styles: ['css/smart-flipbook.css'],
            script: 'js/default-book-view.js',
          },
        };

        if (type === 'images' && imageAttachmentUrls.length > 0) {
          flipBookConfig.pages = imageAttachmentUrls.length;
          flipBookConfig.pageCallback = function (n) {
            return {
              type: 'image',
              src: imageAttachmentUrls[n],
              interactive: false,
            };
          };
        } else {
          flipBookConfig.pdf = pdfAttachmentUrl;
          flipBookConfig.controlsProps.downloadURL = pdfAttachmentUrl;
        }
        window.$('#container').FlipBook(flipBookConfig);
      } catch (flipbookError) {
        console.error('Error initializing FlipBook:', flipbookError);
        window.parent.postMessage({ event: 'flipbookError', message: flipbookError.toString() }, '*');
      }
    };

    // Load required scripts and initialize FlipBook
    const loadResources = async () => {
      try {
        await loadScript('/smart_flipbook/js/jquery.min.js');
        await loadScript('/smart_flipbook/js/html2canvas.min.js');
        await loadScript('/smart_flipbook/js/three.min.js');
        await loadScript('/smart_flipbook/js/pdf.min.js');
        await loadScript('/smart_flipbook/js/3dflipbook.js');
        initializeFlipBook();
      } catch (error) {
        console.error('Error loading scripts:', error);
        window.parent.postMessage({ event: 'flipbookError', message: error.toString() }, '*');
      }
    };

    loadResources();
  }, [type, pdfAttachmentUrl, imageAttachmentUrls, primaryColor]);

  return <div className="container" id="container"></div>;
};

export default WidgetIframe;
