import React from 'react';
import {
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ActionCard } from "shared/organisms/ActionCard";
import { CheckCircleIcon } from "@chakra-ui/icons";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <Flex
      align="center"
      justify="center"
      w="full"
      px="6"
    >
      <ActionCard
        icon={CheckCircleIcon}
        title="Go back to editor and refresh the page"
        description="Your payment was succesful. To make changes appear you need to open Wix editor tab again and refresh the whole page"
        buttonText="Video Guide"
        onButtonClick={() => navigate('/etsy_easy_sync/settings')}
        buttonColor="gray"
      />
    </Flex>
  );
};

export default PaymentSuccess;
