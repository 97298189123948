import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  useColorModeValue,
  VStack,
  Icon,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react';
import { ExternalLinkIcon, LockIcon, CheckCircleIcon } from '@chakra-ui/icons';

const Login = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');
  const highlightColor = useColorModeValue('blue.600', 'blue.300');

  const handleGoToWix = () => {
    window.location.href = 'https://manage.wix.com/studio/sites';
  };

  return (
    <Flex
      minHeight="100vh"
      width="100%"
      align="center"
      justify="center"
      bg={bgColor}
      p={4}
    >
      <Box
        bg={cardBgColor}
        p={10}
        borderRadius="lg"
        boxShadow="xl"
        maxW="lg"
        w="full"
      >
        <VStack spacing={4} align="center">
          <Icon as={LockIcon} w={16} h={16} color={highlightColor} />

          <Heading
            size="xl"
            color={headingColor}
            textAlign="center"
          >
            You have been logged out
          </Heading>

          <Text
            color={textColor}
            textAlign="center"
            fontSize="lg"
            mt={3}
          >
            To continue using this app, please follow these steps:
          </Text>

          <List spacing={3} textAlign="left" width="full" pl={4}>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={highlightColor} />
              Go back to <Text as="span" fontWeight="bold" color={highlightColor}>Wix</Text>
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={highlightColor} />
              Select your <Text as="span" fontWeight="bold" color={highlightColor}>website</Text>
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={highlightColor} />
              Navigate to <Text as="span" fontWeight="bold" color={highlightColor}>Apps → Manage Apps</Text>
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color={highlightColor} />
              Open this <Text as="span" fontWeight="bold" color={highlightColor}>app</Text> again
            </ListItem>
          </List>

          <Button
            colorScheme="blue"
            size="lg"
            rightIcon={<ExternalLinkIcon />}
            onClick={handleGoToWix}
            width="full"
            height="60px"
            fontSize="lg"
            mt={6}
          >
            Go to Wix
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Login;
