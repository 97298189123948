import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Switch,
  useColorModeValue,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import { DownloadIcon, SettingsIcon } from '@chakra-ui/icons';
import NoticeAlert from 'shared/molecules/NoticeAlert';
import PaginatedTable from 'shared/organisms/PaginatedTable';
import ProductSelector from '../molecules/ProductSelector';
import ProductCard from '../molecules/ProductCard';
import axiosInstance from 'utils/axiosInstance';
import formatToLocalTime from 'utils/timeTools';
import { useNavigate } from 'react-router-dom';

const ProductMappings = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [masterStore, setMasterStore] = useState('wix');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [syncInfo, setSyncInfo] = useState(null);
  const [isAlertLoading, setIsAlertLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [configLoading, setConfigLoading] = useState(false);
  const [configData, setConfigData] = useState({
    sync_title: false
  });
  const pageSize = 25;

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch Data
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/etsy_easy_sync/api/product_mappings?page_size=${pageSize}&page=${currentPage}`
      );
      setData(response.data.data || []);
      setMasterStore(response.data.master_store);
      setTotalPages(response.data.pagination?.total_pages || 1);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      toast({
        title: 'Error',
        description: 'Failed to load product mappings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, toast]);

  // Fetch Synchronization Info
  const fetchLastSyncInfo = useCallback(async () => {
    setIsAlertLoading(true);
    try {
      const { data } = await axiosInstance.get(`/etsy_easy_sync/api/sync_processes?page_size=1`);
      const latestSync = data?.data?.[0];
      if (!latestSync) {
        setSyncInfo({ hasHistory: false });
        return;
      }

      const startedAt = formatToLocalTime(latestSync.started_at);
      const nextScheduledAt = formatToLocalTime(latestSync.next_scheduled_at);
      const completedAt = formatToLocalTime(latestSync.completed_at);

      const messages = {
        in_progress: `Sync started at ${startedAt} and is still in progress.`,
        completed: `Sync completed at ${completedAt}. ${latestSync.synced_products_count} products were synced successfully.`,
        failed: `Sync started at ${startedAt} but failed to complete. Please retry.`,
      };

      setSyncInfo({
        hasHistory: true,
        message:
          messages[latestSync.status] ||
          `Last sync started at ${startedAt}. Status ${latestSync.status}, next scheduled at ${
            nextScheduledAt || 'N/A'
          }`,
      });
    } catch (error) {
      console.error('Failed to load sync info:', error);
      toast({
        title: 'Error',
        description: 'Failed to load synchronization information.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      setSyncInfo({ hasHistory: false, message: 'Failed to load synchronization history.' });
    } finally {
      setIsAlertLoading(false);
    }
  }, [toast]);

  // Fetch config for a specific product mapping
  const fetchConfig = async (id) => {
    setConfigLoading(true);
    try {
      const response = await axiosInstance.get(`/etsy_easy_sync/api/product_mappings/${id}/configs/edit`);
      setConfigData(response.data || { sync_title: false });
    } catch (error) {
      console.error('Failed to fetch config:', error);
      toast({
        title: 'Error',
        description: 'Failed to load configuration settings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      setConfigData({ sync_title: false });
    } finally {
      setConfigLoading(false);
    }
  };

  // Save config for a specific product mapping
  const saveConfig = async () => {
    try {
      await axiosInstance.put(
        `/etsy_easy_sync/api/product_mappings/${selectedRecord.id}/configs`,
        { product_mapping_config: { sync_title: configData.sync_title } }
      );

      toast({
        title: 'Success',
        description: 'Configuration saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      console.error('Failed to save config:', error);
      toast({
        title: 'Error',
        description: 'Failed to save configuration settings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Force Sync
  const fetchProductMappings = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/etsy_easy_sync/api/product_mappings/fetch`);
      setData(response.data.data || []);
      setTotalPages(response.data.pagination?.total_pages || 1);
      toast({
        title: 'Refresh Successful',
        description: 'Product mappings have been refreshed.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Failed to refresh data:', error);
      toast({
        title: 'Error',
        description: 'Failed to refresh product mappings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const forceSyncProcess = async () => {
    setIsAlertLoading(true);
    try {
      await axiosInstance.post(`/etsy_easy_sync/api/sync_processes/force`);

      toast({
        title: 'Sync Triggered',
        description: 'Products are in the process of synchronization.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/etsy_easy_sync/synchronization_history');
    } catch (error) {
      console.error('Failed to start synchronization:', error);
      toast({
        title: 'Error',
        description: 'Failed to start product synchronization.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsAlertLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLastSyncInfo();
  }, [fetchData, fetchLastSyncInfo]);

  // Update Mapping
  const updateMapping = async (id, updates, updatedProductKey) => {
    try {
      const currentMapping = data.find((item) => item.id === id);

      // Check if the updates differ from the current mapping
      const hasChanges = Object.keys(updates).some(key => {
        return !currentMapping.hasOwnProperty(key) ||
          updates[key] !== currentMapping[key] ||
          (updates[key] === undefined && currentMapping[key] !== undefined);
      });

      if (!hasChanges) { return; }

      const isProductUpdate = 'wix_product_id' in updates || 'etsy_product_id' in updates;
      if (isProductUpdate) {
        const processedUpdates = { ...updates };
        if ('wix_product_id' in processedUpdates && processedUpdates.wix_product_id === undefined) {
          processedUpdates.wix_product_id = null;
        }
        if ('etsy_product_id' in processedUpdates && processedUpdates.etsy_product_id === undefined) {
          processedUpdates.etsy_product_id = null;
        }

        await axiosInstance.put(`/etsy_easy_sync/api/product_mappings/${id}`, processedUpdates);
        await fetchData();
      } else {
        await axiosInstance.put(`/etsy_easy_sync/api/product_mappings/${id}`, updates);
        setData((prev) =>
          prev.map((item) =>
            item.id === id
              ? {
                ...item,
                [updatedProductKey]: updates[updatedProductKey],
                ...updates,
              }
              : item
          )
        );
      }

      toast({
        title: 'Update Successful',
        description: 'Mapping updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Failed to update mapping:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to update mapping.',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Handle opening config modal
  const handleConfigOpen = (row) => {
    setSelectedRecord(row);
    fetchConfig(row.id);
    onOpen();
  };

  // Columns Definition
  let columns = [
    {
      header: `Etsy Product ${masterStore === 'etsy' ? '(Master Store)' : ''}`,
      key: 'etsy_product',
      render: (row) =>
        row.etsy_product == null || row.etsy_product.is_editable ? (
          <ProductSelector
            vendor='etsy'
            currentProduct={row.etsy_product}
            searchProducts={(term) =>
              axiosInstance
                .get(`/etsy_easy_sync/api/products/search?platform=etsy&term=${term}`)
                .then((res) => res.data.results)
            }
            onSelect={(product) =>
              updateMapping(
                row.id,
                { etsy_product_id: product?.etsy_id },
                'etsy_product'
              )
            }
          />
        ) : (
          <ProductCard {...row.etsy_product} />
        ),
    },
    {
      header: `Wix Product ${masterStore === 'wix' ? '(Master Store)' : ''}`,
      key: 'wix_product',
      render: (row) =>
        row.wix_product == null || row.wix_product.is_editable ? (
          <ProductSelector
            vendor='wix'
            currentProduct={row.wix_product}
            searchProducts={(term) =>
              axiosInstance
                .get(`/etsy_easy_sync/api/products/search?platform=wix&term=${term}`)
                .then((res) => res.data.results)
            }
            onSelect={(product) =>
              updateMapping(
                row.id,
                { wix_product_id: product?.wix_id },
                'wix_product'
              )
            }
          />
        ) : (
          <ProductCard {...row.wix_product} />
        ),
    },
    {
      header: 'Last Sync',
      key: 'last_sync',
      render: (row) => <span>{formatToLocalTime(row.last_sync)}</span>, // Using date-fns for local time
    },
    {
      header: 'Run Sync',
      key: 'sync_enabled',
      render: (row) => (
        <Switch
          size="md"
          colorScheme="blue"
          isChecked={row.sync_enabled}
          onChange={(e) => updateMapping(row.id, { sync_enabled: e.target.checked })}
        />
      ),
    },
    {
      header: 'Config',
      key: 'config',
      render: (row) => (
        <IconButton
          aria-label="Configuration"
          icon={<SettingsIcon />}
          colorScheme="blue"
          onClick={() => handleConfigOpen(row)}
        />
      ),
    },
  ];

  // Put master store as the first column
  columns = columns.sort((a, b) =>
    a.key === `${masterStore}_product` ? -1 : b.key === `${masterStore}_product` ? 1 : 0
  );

  return (
    <Box px="4" bg={useColorModeValue('gray.100', 'gray.800')}>
      <Flex justify="space-between" align="center" mb="6">
        <Heading size="lg">Product Mappings</Heading>
        <Button
          aria-label="Refresh"
          rightIcon={<DownloadIcon />}
          colorScheme="blue"
          onClick={fetchProductMappings} // Trigger POST refresh
        >
          Pull New Products
        </Button>
      </Flex>

      <Box mb="6">
        {isAlertLoading ? (
          <NoticeAlert
            label="Latest Synchronization"
            description="Loading..."
            buttonText="Force Sync"
            onClick={forceSyncProcess}
            isLoading={isAlertLoading}
          />
        ) : syncInfo?.hasHistory ? (
          <NoticeAlert
            label="Latest Synchronization"
            description={syncInfo.message}
            buttonText="Force Sync"
            onClick={forceSyncProcess}
            isLoading={isAlertLoading}
          />
        ) : (
          // No history
          <NoticeAlert
            label="Synchronization doesn't happen automatically... yet!"
            description="Before we turn it on, review the product mappings below. They show which products will be synced. Once you're satisfied the mappings are accurate, click 'Start Sync.' From that moment on, all future syncs happen automatically."
            buttonText="Start Sync"
            onClick={forceSyncProcess}
            isLoading={isAlertLoading}
            pulse={true}
          />
        )}
      </Box>

      <Box bg="white" borderRadius="lg" p="6" boxShadow="md">
        <PaginatedTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </Box>

      {/* Configuration Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Synchronization Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sync-title" mb="0">
                  Synchronize Title
                </FormLabel>
                <Switch
                  id="sync-title"
                  isChecked={configData.sync_title}
                  onChange={(e) => setConfigData({ ...configData, sync_title: e.target.checked })}
                  colorScheme="blue"
                  isDisabled={configLoading}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sync-images" mb="0">
                  Synchronize Images
                </FormLabel>
                <Switch
                  id="sync-images"
                  isChecked={true}
                  colorScheme="blue"
                  isDisabled={true}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sync-prices" mb="0">
                  Synchronize Prices
                </FormLabel>
                <Switch
                  id="sync-prices"
                  isChecked={true}
                  colorScheme="blue"
                  isDisabled={true}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sync-inventory" mb="0">
                  Synchronize Inventory
                </FormLabel>
                <Switch
                  id="sync-inventory"
                  isChecked={true}
                  colorScheme="blue"
                  isDisabled={true}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={saveConfig} isLoading={configLoading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProductMappings;
