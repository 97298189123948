import React from 'react';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return { truncated: `${text.substring(0, limit)}...`, isTruncated: true };
  }
  return { truncated: text, isTruncated: false };
};

const ProductCard = ({ name, sku, price, image }) => {
  const charLimit = 30; // Adjust this as needed
  const { truncated, isTruncated } = truncateText(name, charLimit);

  return (
    <Flex align="center" w="full">
      <Image
        src={image || '/etsy_easy_sync/placeholder.png'}
        alt={name}
        boxSize="50px"
        objectFit="cover"
        borderRadius="md"
        mr="3"
      />
      <Box>
        <Tooltip label={name} isDisabled={!isTruncated}>
          <Text fontWeight="bold">{truncated}</Text>
        </Tooltip>
        <Text fontSize="sm" color="gray.500">
          { sku ? `SKU ${sku}  -` : null } {price}
        </Text>
      </Box>
    </Flex>
  );
};

export default ProductCard;
