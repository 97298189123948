import React, { useState } from 'react';
import {
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  Box, Flex, UnorderedList, ListItem, Button, Text
} from '@chakra-ui/react';
import { WarningIcon, RepeatIcon } from '@chakra-ui/icons';

const SyncWarningsAndActions = ({ warnings = [], requiredActions = [], rerunSync }) => {
  const [isLoading, setIsLoading] = useState(false);
  const actionsCount = requiredActions.length;
  const warningsCount = warnings.length;

  const handleRerun = async () => {
    setIsLoading(true);
    try {
      await Promise.resolve(rerunSync()); // Handles both sync and async callbacks
    } catch (error) {
      console.error('Rerun sync failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Show nothing if there are no actions or warnings
  if (actionsCount === 0 && warningsCount === 0) {
    return <Box textAlign="left">-</Box>;
  }

  return (
    <Accordion allowToggle width="full" allowMultiple>
      {/* Required Actions Accordion */}
      {actionsCount > 0 && (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Flex align="center">
                  <WarningIcon color="red.500" mr={2} /> {/* Red icon for actions */}
                  {actionsCount} Action{actionsCount > 1 ? 's' : ''} Required
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList mb={4}>
              {requiredActions.map((action) => (
                <Text dangerouslySetInnerHTML={{ __html: action.message.replace(/\n/g, '<br />') }} />
              ))}
            </UnorderedList>

            {/* "Finished required actions?" section with rerun button */}
            <Flex align="center" justify="space-between" mt={4}>
              <Text fontSize="sm" color="gray.600">
                Finished required actions?
              </Text>
              <Button size="xs" leftIcon={<RepeatIcon />} colorScheme="blue" onClick={handleRerun} isLoading={isLoading}>
                Rerun
              </Button>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      )}

      {/* Warnings Accordion */}
      {warningsCount > 0 && (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Flex align="center">
                  <WarningIcon color="yellow.500" mr={2} /> {/* Yellow icon for warnings */}
                  {warningsCount} Warning{warningsCount > 1 ? 's' : ''}
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList>
              {warnings.map((warning) => (
                <ListItem key={warning.id}>
                  <Text dangerouslySetInnerHTML={{ __html: warning.message.replace(/\n/g, '<br />') }} />
                </ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  );
};

export default SyncWarningsAndActions;
