import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  IconButton,
  Badge,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { RepeatIcon, ViewIcon } from '@chakra-ui/icons';
import PaginatedTable from 'shared/organisms/PaginatedTable';
import axiosInstance from 'utils/axiosInstance';
import formatToLocalTime from 'utils/timeTools';
import humanizeDuration from 'humanize-duration';
import { useNavigate } from "react-router-dom";

const SynchronizationHistory = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState([]); // Table data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const pageSize = 25; // Items per page

  // Fetch data from the API
  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/etsy_easy_sync/api/sync_processes?page_size=${pageSize}&page=${page}`
      );

      setData(response.data.data || []);
      setTotalPages(response.data.pagination?.total_pages || 1);
    } catch (error) {
      console.error('Failed to fetch synchronization history:', error);
      toast({
        title: 'Error',
        description: 'Failed to load synchronization history.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Force Sync Trigger
  const forceSync = async () => {
    try {
      await axiosInstance.post('/etsy_easy_sync/api/sync_processes/force');
      toast({
        title: 'Sync Triggered',
        description: 'Synchronization has been started successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      fetchData(currentPage); // Refresh current page
    } catch (error) {
      console.error('Failed to trigger sync:', error);
      toast({
        title: 'Error',
        description: 'Failed to start synchronization.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Automatically re-fetch data every 3 seconds if in_progress or pending statuses exist
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (data.some((item) => ['in_progress', 'pending'].includes(item.status))) {
        fetchData(currentPage);
      }
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [data, currentPage]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // Map status to Badge
  const getStatusBadge = (status) => {
    const colorScheme = {
      pending: 'yellow',
      in_progress: 'yellow',
      action_required: 'orange',
      synced: 'green',
      failed: 'red',
    }[status]

    return (
      <Badge colorScheme={colorScheme} rounded="full" px={2} size="sm">
        {status.replace('_', ' ')} {/* Convert snake_case to readable */}
      </Badge>
    );
  };

  // Convert seconds to human-readable duration
  const formatDuration = (seconds) => {
    if (!seconds) return '-';
    return humanizeDuration(seconds * 1000, {
      largest: 2, // Show up to two units (e.g., 1h 10m)
      round: true,
      units: ['h', 'm'],
    });
  };

  // Define table columns
  const columns = [
    {
      header: 'Started At',
      key: 'started_at',
      render: (row) => formatToLocalTime(row.started_at), // Localized time
    },
    {
      header: 'Status',
      key: 'status',
      render: (row) => getStatusBadge(row.status),
    },
    {
      header: 'Products Synced',
      key: 'synced_products_count',
      render: (row) => (
        <Box style={{ lineHeight: '1.5' }}>
          <Text size="sm" as='b'>
            {`${row.synced_products_count} / ${row.products_count} products`}
          </Text>
          <Text size="xs" style={{ marginTop: '4px' }}>
            {`${row.actions_required_count > 0 ? row.actions_required_count : 'No' } action(s) required`}
          </Text>
        </Box>
      ),
    },
    {
      header: 'Duration',
      key: 'duration',
      render: (row) => formatDuration(row.duration),
    },
    {
      header: 'Actions',
      key: 'actions',
      render: (row) => (
        <IconButton
          aria-label="View Details"
          icon={<ViewIcon />}
          colorScheme="blue"
          size="sm"
          onClick={() => navigate(`/etsy_easy_sync/synchronization_history/${row.id}`)}
        />
      ),
    },
  ];

  return (
    <Box px="4" bg={useColorModeValue('gray.100', 'gray.800')}>
      {/* Header */}
      <Flex justify="space-between" align="center" mb="6">
        <Heading as="h1" size="lg" color={useColorModeValue('gray.800', 'white')}>
          Synchronization History
        </Heading>
        <IconButton
          aria-label="Force Sync"
          icon={<RepeatIcon />}
          colorScheme="blue"
          size="md"
          onClick={forceSync}
          isLoading={isLoading}
        />
      </Flex>

      {/* Synchronization History Table */}
      <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" boxShadow="md" p="6">
        <PaginatedTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage} // Update the page on pagination
        />
      </Box>
    </Box>
  );
};

export default SynchronizationHistory;
