import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  useColorModeValue,
  Badge,
  Spinner,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Checkbox, Divider,
} from '@chakra-ui/react';
import { ArrowBackIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import PaginatedTable from 'shared/organisms/PaginatedTable';
import CreateProductCard from "../molecules/CreateProductCard";
import ProductCard from '../molecules/ProductCard';
import axiosInstance from 'utils/axiosInstance';
import SyncWarningsAndActions from "../organisms/SyncWarningsAndActions";

const ALL_STATUSES = ["pending","in_progress","action_required","synced","failed"];

const SynchronizationRun = () => {
  const toast = useToast();
  const { synchronizationId } = useParams(); // Extract syncProcessId from the URL
  const navigate = useNavigate(); // For back navigation
  const [data, setData] = useState([]); // Table data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 25;

  // Track which statuses are selected
  const [selectedStatuses, setSelectedStatuses] = useState(ALL_STATUSES);

  // Check if all statuses are selected
  const isAllSelected = selectedStatuses.length === ALL_STATUSES.length;

  // Function to toggle status selection
  const handleStatusChange = (status) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  // Function to toggle all statuses
  const handleSelectAll = () => {
    if (isAllSelected) { setSelectedStatuses([]); }
    else { setSelectedStatuses(ALL_STATUSES); }
  };

  // Fetch data for the given sync_process ID
  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      // Pass the selected statuses as a comma-separated param
      const statusesParam = selectedStatuses.join(',');
      const response = await axiosInstance.get(
        `/etsy_easy_sync/api/sync_processes/${synchronizationId}/product_sync_processes?page_size=${pageSize}&page=${page}&status=${statusesParam}`
      );
      setData(response.data.data || []);
      setTotalPages(response.data.pagination?.total_pages || 1);
    } catch (error) {
      console.error('Failed to fetch product sync processes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRerunSync = async (productSyncProcessId) => {
    try {
      await axiosInstance.post(
        `/etsy_easy_sync/api/sync_processes/${synchronizationId}/product_sync_processes/${productSyncProcessId}/rerun`
      );
      await fetchData(currentPage);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to rerun sync. Please reach out to live chat',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Automatically re-fetch data every 3 seconds if in_progress or pending statuses exist
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (data.some((item) => ['in_progress', 'pending'].includes(item.status))) {
        fetchData(currentPage);
      }
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [data, currentPage]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, synchronizationId, selectedStatuses]);

  // Get status badge
  const getStatusBadge = (status) => {
    const colorScheme = {
      synced: 'green',
      in_progress: 'yellow',
      action_required: 'orange',
      pending: 'yellow',
      failed: 'red',
    }[status]

    return (
      <Badge colorScheme={colorScheme} rounded="full" px="2" size="sm">
        {status.replace('_', ' ').charAt(0).toUpperCase() + status.replace('_', ' ').slice(1)}
      </Badge>
    );
  };

  // Define table columns
  const columns = [
    {
      header: 'Etsy Product',
      key: 'etsy_product',
      maxWidth: '200px',
      render: function (row) {
        let product = row.updated_etsy_product || row.etsy_product;
        return product ? <ProductCard {...product} /> : <CreateProductCard hoverBg='' vendor='etsy'/>;
      },
    },
    {
      header: 'Wix Product',
      key: 'wix_product',
      maxWidth: '200px',
      render: function (row) {
        let product = row.updated_wix_product || row.wix_product;
        return product ? <ProductCard {...product} /> : <CreateProductCard hoverBg='' vendor='wix'/>;
      },
    },
    {
      header: 'Status',
      key: 'status',
      render: (row) => getStatusBadge(row.status),
    },
    {
      header: 'Comments',
      key: 'comments',
      maxWidth: '250px',
      render: (row) => (
        <SyncWarningsAndActions
          warnings={row.warnings}
          requiredActions={row.required_actions}
          rerunSync={() => handleRerunSync(row.id)}
        />
      ),
    },
  ];

  return (
    <Box px="4" bg={useColorModeValue('gray.100', 'gray.800')} minH="100vh">
      {/* Header */}
      <Flex justify="space-between" align="center" mb="6">
        <Flex align="center">
          <IconButton
            aria-label="Go Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            size="lg"
            onClick={() => navigate(-1)} // Navigate back
          />
          <Heading as="h1" size="lg" color={useColorModeValue('gray.800', 'white')}>
            Synchronization Run
          </Heading>
        </Flex>
        {/* Filter Menu */}
        <Menu>
          <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
            Filter by Status
          </MenuButton>
          <MenuList>
            <MenuItem closeOnSelect={false}>
              <Checkbox
                isChecked={isAllSelected}
                onChange={handleSelectAll}
              >
                Select All
              </Checkbox>
            </MenuItem>
            <Divider my={1} />
            {ALL_STATUSES.map((status) => (
              <MenuItem key={status} closeOnSelect={false}>
                <Checkbox
                  isChecked={selectedStatuses.includes(status)}
                  onChange={() => handleStatusChange(status)}
                >
                  { getStatusBadge(status) }
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>

      {/* Synchronization Run Table */}
      <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" boxShadow="md" p="6">
        {isLoading ? (
          <Flex justify="center" align="center" minH="200px">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <PaginatedTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default SynchronizationRun;
